import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Privacidad from "./Privacidad";
import ChangePasswordForm from "./Login/componentes/ChangePasswordForm";
import BuscarCorreo from "./Login/componentes/BuscarCorre";
import ReportesPDF from "./Reports/ReportesPDF";
import imgEsperaMantenimiento from "./assets/imgEsperaMantenimiento.svg";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <React.StrictMode>
    <Router>
      <Routes> 
        <Route path="/privacidad/*" element={<Privacidad />}></Route>
        <Route path="/restablecer-contrasena" element={<BuscarCorreo />} />
        <Route
          path="/cambiar-contrasena/:uuid"
          element={<ChangePasswordForm />}
        />
        <Route path="/*" element={<App />} />
        <Route path="/pdf/*" element={<ReportesPDF />}></Route>
      </Routes>
    </Router>
  </React.StrictMode>
);
